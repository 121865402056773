.container {
    margin-bottom: 1rem;
    padding: 1rem;
  }
   
.form {
    width: 100%;
  }
  
  .input {
    width: 100%;
    padding: 15px 17px;
    margin-bottom: 0.8rem;
    border: 1px solid #707070;
  }
  
  button {
    display: block;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    padding: 15px 17px;
    background: #b4b4b4;
  }