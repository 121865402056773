.container {
    min-height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
}

.buttons {
    display: flex;
    padding: 10px;
}

.summary {
    margin: 30px 30px;
    text-align: left;
}