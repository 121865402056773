.wrapper {
    display: flex;  
    font-weight: bold;
    text-align: center; 
    flex-direction: column;
    height: 100vh;
  }
  

  main {
    flex: 1;
}
  
  .header {
    height: 40px;
  }
  
  .buttons {
    display: flex;
    flex-direction: row-reverse;
    flex: 0;
    margin: 10px;
  }

  .button {
    width: 50px;
    height: 25px;
    padding: 0;
    margin: 5px;
  }

  .footer {
    height: 40px;
  }
  
  
  img {
    width: 100%;
    height: 100%;
  }
