.container {
    display: flex;
    padding-left: 2rem;
    align-items: flex-start
  }

  .item {
    width: 350px;
    text-align: left;

  }
  
.text {
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  