.container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .button {
    background: lightgrey;
    margin: 10px;
    border: none;
  }
  
  
  .selected {
    background: grey;
  }
  
